<template>
  <section class="page-index">
    <van-swipe
      ref="swipeContainer"
      class="van-swipe-container"
      :loop="false"
      :show-indicators="false"
      :vertical="true"
      :lazy-render="true"
      @change="handleSwipeChange"
    >
      <van-swipe-item
        class="swipe-item swipe-item-1"
        v-lazy:background-image="
          '//static.hoolihome.com/global-jwp/assets/m/home/bg.jpg'
        "
      >
        <div class="slogon">
          <p class="en">
            Steady International Asset Investment Group With Sustainable
            Value-creating
          </p>
        </div>
        <ul class="data-show">
          <li class="item country">
            <img
              src="//static.hoolihome.com/global-jwp/assets/m/home/icon-country.png"
            />
            <div class="r">
              <count-to
                ref="countCountry"
                :start-val="0"
                :end-val="9"
                :duration="3000"
                :decimals="0"
                :autoplay="false"
                class="num"
              ></count-to>
              <p class="van-hairline--surround line"></p>
              <p class="text">Countries</p>
            </div>
          </li>
          <li class="item city">
            <img
              src="//static.hoolihome.com/global-jwp/assets/m/home/icon-city.png"
            />
            <div class="r">
              <count-to
                ref="countCity"
                :start-val="0"
                :end-val="40"
                :duration="3000"
                :decimals="0"
                :autoplay="false"
                class="num"
              ></count-to>
              <p class="van-hairline--surround line"></p>
              <p class="text">Cities</p>
            </div>
          </li>
          <li class="item asset">
            <img
              src="//static.hoolihome.com/global-jwp/assets/m/home/icon-asset.png"
            />
            <div class="r">
              <count-to
                ref="countAssets"
                :start-val="0"
                :end-val="2800"
                :duration="3000"
                :decimals="0"
                :autoplay="false"
                class="num"
              ></count-to>
              <p class="van-hairline--surround line"></p>
              <p class="text">Million Asset Value</p>
            </div>
          </li>
        </ul>
        <img
          class="arrow-down"
          src="//static.hoolihome.com/global-jwp/assets/m/arrow-down.png"
          @click="handleNextScreen"
        />
      </van-swipe-item>
      <van-swipe-item class="swipe-item swipe-item-2">
        <template v-if="flag">
          <van-swipe
            class="swipe-inner"
            :autoplay="8000"
            indicator-color="white"
          >
            <van-swipe-item>
              <van-image
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/home/japan-en.jpg"
              />
            </van-swipe-item>
            <van-swipe-item>
              <van-image
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/home/thailand-en.jpg"
              />
            </van-swipe-item>
            <van-swipe-item>
              <van-image
                lazy-load
                src="//static.hoolihome.com/global-jwp/assets/m/home/turkey-en.jpg"
              />
            </van-swipe-item>
          </van-swipe>
          <ul class="box">
            <li class="box-item">
              <a
                class="link"
                href="/landmark"
                v-lazy:background-image="
                  '//static.hoolihome.com/global-jwp/assets/m/home/developer.png'
                "
              >
                <p class="en">JWP Developer</p>
              </a>
            </li>
            <li class="box-item">
              <a
                class="link"
                href="/fund"
                v-lazy:background-image="
                  '//static.hoolihome.com/global-jwp/assets/m/home/fund.png'
                "
              >
                <p class="en">JWP Fund</p>
              </a>
            </li>
            <li class="box-item">
              <a
                class="link"
                href="/landmark/land"
                v-lazy:background-image="
                  '//static.hoolihome.com/global-jwp/assets/m/home/land.png'
                "
              >
                <p class="en">JWP Land</p>
              </a>
            </li>
            <li class="box-item">
              <a
                class="link"
                href="/landmark/property-services"
                v-lazy:background-image="
                  '//static.hoolihome.com/global-jwp/assets/m/home/property.png'
                "
              >
                <p class="en">Property Services</p>
              </a>
            </li>
          </ul>
          <my-footer></my-footer>
        </template>
      </van-swipe-item>
    </van-swipe>
  </section>
</template>

<script>
import { Swipe as VanSwipe, SwipeItem as VanSwipeItem } from "vant";
import CountTo from "vue-count-to";
import MyFooter from "@/components/common/MyFooter";

export default {
  components: { VanSwipe, VanSwipeItem, CountTo, MyFooter },
  data() {
    return {
      flag: false
    };
  },
  beforeCreate() {
    document.title = "Homepage";
  },
  mounted() {
    window.setTimeout(() => {
      this.countStart();
    }, 300);
  },
  methods: {
    handleSwipeChange(index) {
      if (index === 0) {
        this.countStart();
      } else if (index === 1) {
        this.flag = true;
      }
    },
    handleNextScreen() {
      this.$refs.swipeContainer.next();
    },
    countStart() {
      this.$refs.countCountry.start();
      this.$refs.countCity.start();
      this.$refs.countAssets.start();
    }
  }
};
</script>

<style lang="less" scoped>
@keyframes scroll {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.page-index {
  .van-swipe-container {
    height: 100vh;
    .swipe-item {
      position: relative;
      &.swipe-item-1 {
        background-color: #ffffff;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .slogon {
          padding: 38.31vh 34px 0;
          p {
            color: rgba(255, 255, 255, 1);
            text-align: center;
          }
          .en {
            line-height: 30px;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .data-show {
          padding: 25.21vh 24px 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .item {
            display: flex;
            align-items: center;
            max-width: 150px;
            img {
              width: 30px;
              height: 30px;
            }
            &.city {
              img {
                width: 26px;
                height: 33px;
              }
            }
            .r {
              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              margin-left: 8px;
              color: #ffffff;
              .num {
                font-size: 18px;
                font-weight: bold;
              }
              .line {
                margin: 2px 0 6px;
                &::after {
                  border-color: #ffffff;
                }
              }
              .text {
                font-size: 10px;
              }
            }
          }
        }
        .arrow-down {
          position: absolute;
          bottom: 5vh;
          left: calc(50vw - 11.5px);
          width: 23px;
          height: 15px;
          animation: scroll 1.5s infinite;
          &:active {
            opacity: 0.9;
          }
        }
      }
      &.swipe-item-2 {
        .swipe-inner {
          height: 59.7vh;
          .van-swipe-item {
            .van-image {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          /deep/ .van-swipe__indicator {
            width: 6px;
            height: 4px;
            background: rgba(255, 255, 255, 1);
            border-radius: 2px;
            opacity: 0.6958;
            &.van-swipe__indicator--active {
              width: 18px;
              background: rgba(28, 80, 199, 1) !important;
            }
          }
        }
        .box {
          display: flex;
          flex-flow: row wrap;
          padding: 2.65vh 5.86vw 0;
          .box-item {
            width: 42.13vw;
            height: 13.26vh;
            margin-bottom: 2.65vh;
            &:not(:nth-child(2n + 2)) {
              margin-right: 4vw;
            }
            .link {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;
              border-radius: 2px;
              background-color: #ffffff;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              &:active {
                opacity: 0.9;
              }
              .en {
                font-size: 18px;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
                text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
              }
            }
          }
        }
        .my-footer {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>
